import { BreadcrumbItem } from 'modules/Layout/type';
import { getPathUrl } from 'modules/Shared/helper/api';
import { OFFICES_ADD, OFFICES_EXTENDED, OFFICES_IMPORT, OFFICES_INDEX } from 'modules/Office/permissions';
import {
  ROUTE_OFFICE,
  ROUTE_OFFICES,
  ROUTE_OFFICES_EMAIL,
  ROUTE_OFFICE_IMPORTS,
  ROUTE_OFFICES_DOT,
  ROUTE_OFFICE_EXTENDED
} from 'modules/Office/routes';

export const breadcrumbOffices = (): BreadcrumbItem => ({
  title: 'Urzędy podstawowe',
  link: ROUTE_OFFICES,
  permissions: [OFFICES_INDEX]
});

export const breadcrumbOfficesDot = (): BreadcrumbItem => ({
  title: 'Urzędy dot. PA',
  link: ROUTE_OFFICES_DOT,
  permissions: [OFFICES_INDEX]
});

export const breadcrumbRouteOffices = (): BreadcrumbItem[] => [
  {
    title: 'Urzędy podstawowe - lista'
  }
];

export const breadcrumbRouteOfficesDot = (): BreadcrumbItem[] => [
  {
    title: 'Urzędy dot. PA'
  }
];

export const breadcrumbRouteOfficeImports = (): BreadcrumbItem[] => [
  {
    title: 'Urzędy – Importuj pliki',
    link: ROUTE_OFFICE_IMPORTS,
    permissions: [OFFICES_IMPORT]
  }
];

export const breadcrumbRouteOfficeImport = (file_name: string): BreadcrumbItem[] => [
  ...breadcrumbRouteOfficeImports(),
  {
    title: `Importowany plik${file_name ? ` - ${file_name}` : ''}`
  }
];

export const breadcrumbRouteOffice = (id: string | number, name?: string): BreadcrumbItem[] => [
  breadcrumbOffices(),
  {
    title: `${name || 'Urzędy podstawowe'} - szczegóły`,
    link: getPathUrl(ROUTE_OFFICE, { id }),
    permissions: [OFFICES_ADD]
  }
];

export const breadcrumbRouteOfficeExtended = (id: string | number, name?: string): BreadcrumbItem[] => [
  breadcrumbOfficesDot(),
  {
    title: `${name || 'Urzędy dot. PA'} - szczegóły`,
    link: getPathUrl(ROUTE_OFFICE_EXTENDED, { id }),
    permissions: [OFFICES_EXTENDED]
  }
];

export const breadcrumbRouteExecutorRequest = (id: string | number, name?: string): BreadcrumbItem[] => [
  ...breadcrumbRouteOffice(id, name),
  {
    title: 'Zapytania'
  }
];

export const breadcrumbRouteExtendedExecutorRequest = (id: string | number, name?: string): BreadcrumbItem[] => [
  ...breadcrumbRouteOffice(id, name),
  {
    title: 'Zapytania dot PA'
  }
];

export const breadcrumbRouteOfficeCreate = (): BreadcrumbItem[] => [
  breadcrumbOffices(),
  {
    title: 'Tworzenie'
  }
];

export const breadcrumbRouteOfficeUpdate = (id: number, name?: string): BreadcrumbItem[] => [
  ...breadcrumbRouteOffice(id, name),
  {
    title: 'Edycja danych podstawowych'
  }
];

export const breadcrumbRouteOfficeEmail = (): BreadcrumbItem[] => [
  breadcrumbOffices(),
  {
    title: 'Komunikacja z urzędami - email',
    link: ROUTE_OFFICES_EMAIL
  }
];

export const breadcrumbRouteOfficeEmailResend = (): BreadcrumbItem[] => [
  ...breadcrumbRouteOfficeEmail(),
  {
    title: 'Wysyłanie email'
  }
];
