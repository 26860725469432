import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import { ExecutorFinancialReport } from '../../../model/Violation';
import { formatDate, ifContainsDateFormat } from '../../../../Shared/helper/utils';
import ActionUpdate from '../../../../Layout/component/Action/Update';
import DownloadIcon from '../../../../Layout/component/Icon/Download';

export type FinancialReportsTableProps = {
  financial_reports: ExecutorFinancialReport[];
  downloadFile?: (id: number | string) => void;
};

const FinancialReportsTable = ({ financial_reports, downloadFile }: FinancialReportsTableProps) => {
  const cols: TableCol<ExecutorFinancialReport>[] = [
    {
      property: 'submission_date',
      label: 'Data złożenia',
      value: (row) => ifContainsDateFormat(row?.submission_date, formatDate)
    },
    {
      property: 'report_period',
      label: 'Za okres',
      value: (row) => ifContainsDateFormat(row?.report_period, formatDate)
    },
    {
      property: 'file_name',
      label: 'Nazwa pliku',
      value: (row) => row?.file_name
    },
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            {row?.file_name && (
              <>
                <ActionUpdate
                  className="mx-1"
                  title="Pobierz plik"
                  label={<DownloadIcon height="20px" />}
                  onClick={() => downloadFile(row.id)}
                />
              </>
            )}
          </div>
        );
      }
    }
  ];

  return <Table cols={cols} rows={financial_reports} />;
};

export default FinancialReportsTable;
