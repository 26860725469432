import CardBox from 'modules/Layout/component/CardBox';
import React from 'react';
import { ExecutorExtendedRequest } from '../../../../Executor/model/Violation';
import ExecutorExtendedRequestBasicDataset from '../../ExecutorExtendedRequestDataset/Basic';
import ExecutorRequestFileListTable from '../../../../Office/container/ExecutorRequestFileList';

export interface Props {
  executorExtendedRequest: ExecutorExtendedRequest;
  downloadFileRequest?: (fileId: number | string) => Promise<void>;
}

const ExecutorExtendedRequestDetailsBasic: React.FC<Props> = ({ executorExtendedRequest, downloadFileRequest }) => {
  return (
    <CardBox heading="Dane wniosku" paddingLeft="30px" paddingTop="25px" paddingRight="30px" paddingBottom="0">
      <ExecutorExtendedRequestBasicDataset executorExtendedRequest={executorExtendedRequest} />
      <div style={{ padding: '0 30px' }} className="information-list-item mt-2">
        <p className="information-list-paragraph">
          <strong style={{ marginRight: '0.25rem' }}>Załączniki:</strong>
          {!executorExtendedRequest?.files.length ? <span className="value">brak</span> : null}
        </p>
        {executorExtendedRequest?.files?.length ? (
          <ExecutorRequestFileListTable
            downloadFileRequest={downloadFileRequest}
            filesList={executorExtendedRequest?.files}
          />
        ) : null}
      </div>
    </CardBox>
  );
};

export default ExecutorExtendedRequestDetailsBasic;
