import { RootState } from 'app/reducer';
import Loader from 'modules/Layout/component/Loader';
import Page403 from 'modules/Layout/component/Page/403';
import Page404 from 'modules/Layout/component/Page/404';
import Page500 from 'modules/Layout/component/Page/500';
import Breadcrumb from 'modules/Layout/container/Page/Breadcrumb';
import { BreadcrumbItem, PageProps } from 'modules/Layout/type';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import bsBanner from '../../../Auth/view/Login/bs-banner.png';

export type StateProps = PageProps;

export interface Props extends StateProps, Readonly<{ children?: ReactNode }> {
  defaultBreadcrumbItem?: BreadcrumbItem;
  noSideNavBar?: boolean;
}

export const mapState = (state: RootState): StateProps => {
  const { title, type, breadcrumb } = state.layout.page;

  return { title, type, breadcrumb };
};

export class Page extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.renderType = this.renderType.bind(this);
  }

  renderType(): ReactNode {
    const { type } = this.props;

    switch (type) {
      case 'loading':
        return <Loader />;
      case '403':
        return <Page403 />;
      case '404':
        return <Page404 />;
      case '500':
        return <Page500 />;
      default:
        return null;
    }
  }

  render(): ReactNode {
    const { title, breadcrumb, defaultBreadcrumbItem, noSideNavBar, children } = this.props;

    return (
      <div className={`${noSideNavBar ? 'content-page ml-0' : 'content-page'} ${process.env.THEME_TEMPLATE}`}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex flex-wrap flex-wrap align-items-center justify-content-between">
                  <p className="h4 page-title text-primary">{title}</p>
                  <Breadcrumb defaultItem={defaultBreadcrumbItem} items={breadcrumb} />
                </div>
              </div>
            </div>
            <div className="page-view" style={noSideNavBar ? { padding: '50px' } : {}}>
              {children}
            </div>
            {this.renderType()}
          </div>
        </div>

        {process.env.THEME_TEMPLATE === 'bs' && (
          <div className="bs-footer-banner">
            <img className="topnav-menu-banner" src={bsBanner} alt="bs banner" />
          </div>
        )}
      </div>
    );
  }
}

export default connect<StateProps>(mapState)(Page);
