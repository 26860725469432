import React, { FC, useState } from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { createSearchingProps } from 'modules/Shared/helper/params';
import { ROUTE_USERS_EXECUTORS_EXTENDED } from 'modules/User/routes';
import { useDispatch, useSelector } from 'react-redux';
import ExecutorExtendedTable from 'modules/User/container/Table/ExecutorExtended';
import ExecutorExtendedFilter from 'modules/User/container/Filter/ExecutorExtended';
import ExecutorsExtendedPagination from 'modules/User/container/Pagination/ExecutorsExtended';
import ExecutorExtendedPerPage from 'modules/User/container/PerPage/ExecutorExtended';
import { push } from 'connected-react-router';
import { Button } from 'reactstrap';
import { Message, ValidationErrors } from 'modules/Shared/type';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import { useCreditReportClaimAdmin } from 'modules/Executor/repository';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_EXECUTOR } from 'modules/Executor/routes';
import useCancelToken from '../../../../Shared/helper/hooks/useCancelToken';
import ModalUseCreditAdmin from '../../../../Executor/component/Modal/UseCreditReportClaimAdmin';
import Executor from '../../../../Executor/model/Executor';

const ExecutorsExtendedList: FC = () => {
  const { fetching, meta } = useSelector((state: RootState) => state.user.executorsExtendedList);
  const [displayUseCreditModal, setDisplayUseCreditModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const dispatch = useDispatch();

  const searchProps = createSearchingProps('', Executor.getFilterableAttributes(), Executor.getSortableAttributes());

  const cancelToken = useCancelToken();
  const requestUseCreditReportClaimAction = async (data: { nip: string; is_construction_company?: boolean }) => {
    if (loading) return;
    setLoading(true);
    const {
      data: resData,
      message: resMessage,
      error,
      cancelled
    } = await updateRequestWithData(useCreditReportClaimAdmin, data, cancelToken);

    if (cancelled) return;
    if (resData?.data) {
      const { executor_id: id } = resData.data;
      if (id) {
        dispatch(push(getPathUrl(ROUTE_EXECUTOR, { id })));
      }
    } else {
      if (resMessage) setMessage(resMessage);
      if (error) setErrors(error);
    }
    setLoading(false);
  };

  const cleanMessage = () => {
    setMessage(null);
    setErrors(null);
  };

  return (
    <div className="row users-view">
      {displayUseCreditModal && (
        <ModalUseCreditAdmin
          onUseCreditClick={(data: { nip: string; is_construction_company?: boolean }) =>
            requestUseCreditReportClaimAction(data)
          }
          loading={loading}
          setLoading={(l) => setLoading(l)}
          message={message}
          errors={errors}
          cleanMessage={cleanMessage}
          isOpen={displayUseCreditModal}
          toggle={() => setDisplayUseCreditModal(!displayUseCreditModal)}
        />
      )}
      <div className="col-12 mb-2 d-flex justify-content-end">
        <Button
          color="dark"
          className="pzpeu-btn-flex waves-effect waves-light access-btn"
          onClick={() => setDisplayUseCreditModal(true)}
        >
          Dodaj pogłębioną analizę
        </Button>
      </div>
      <div className="col-12">
        <Listing
          table={<ExecutorExtendedTable />}
          filter={<ExecutorExtendedFilter values={searchProps.filter} />}
          pagination={<ExecutorsExtendedPagination path={ROUTE_USERS_EXECUTORS_EXTENDED} />}
          perPage={<ExecutorExtendedPerPage />}
          loading={fetching}
          meta={meta}
        />
      </div>
    </div>
  );
};

export default ExecutorsExtendedList;
