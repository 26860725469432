import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import DetailsComponent from 'modules/Office/component/Details/Office/Details';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message, ValidationErrors } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import { push } from 'connected-react-router';
import Alert from 'modules/Layout/component/Alert';
import { breadcrumbRouteOffice } from 'modules/Office/breadcrumbs';
import Office, { createOffice } from 'modules/Office/model/Office';
import { ROUTE_OFFICES } from 'modules/Office/routes';
import { deleteOfficeToastSuccess, executorRequestReceivedToastSuccess } from 'modules/Office/toasts';
import { deleteOffice, executorRequestReceived, fetchOffice } from 'modules/Office/repository';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { OfficeExecutorRequestReceivedValueSend } from 'modules/Office/type';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import ExecutorRequestReceivedModal from 'modules/Office/component/Modal/ExecutorRequestReceived';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';

const Details = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [office, setOffice] = useState<Office>(null);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const [fetching, setFetching] = useState(false);
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [displayExecutorRequestReceivedModal, setDisplayExecutorRequestReceivedModal] = useState<number>(null);
  const [loading, setLoading] = useState(false);

  const dispatchDeleteUserToast = () => dispatch(addToastAction(deleteOfficeToastSuccess()));

  const displayDeleteModalAction = () => setDisplayDeleteModal(true);

  const dispatchExecutorRequestReceivedToast = () => dispatch(addToastAction(executorRequestReceivedToastSuccess()));

  const cancelToken = useCancelToken();

  const fetchOfficeRequest = async () => {
    setFetching(true);
    const { data, cancelled, message: messageResponse } = await fetchRequest(fetchOffice, id, cancelToken);

    if (cancelled) return;
    if (data) setOffice(createOffice(data.data));
    if (messageResponse) setMessage(messageResponse);
    setFetching(false);
  };

  useEffect(() => {
    fetchOfficeRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (office) {
      const title = `${office.name?.trim() || 'Urzedy'} - szczegóły`;
      dispatch(
        managePageAction({
          title,
          breadcrumb: breadcrumbRouteOffice(id, office.name?.trim())
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [office]);

  const deleteOfficeAction = async (user_id: number | string) => {
    setLoading(true);
    const { cancelled, message: messageRes, success } = await deleteRequest(deleteOffice, user_id, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDeleteUserToast();
      dispatch(push(ROUTE_OFFICES));
    } else {
      if (messageRes) setMessage(messageRes);
      setLoading(false);
    }
  };

  const executorRequestReceivedRequest = async (data: OfficeExecutorRequestReceivedValueSend) => {
    setLoading(true);

    if (!displayExecutorRequestReceivedModal) return;

    if (message) setMessage(null);
    if (errors) setErrors(null);

    const {
      cancelled,
      message: messageRes,
      data: responseData,
      error
    } = await updateRequestWithData(
      executorRequestReceived,
      id,
      displayExecutorRequestReceivedModal,
      data,
      cancelToken
    );

    if (cancelled) return;
    if (responseData) {
      dispatchExecutorRequestReceivedToast();
      setDisplayExecutorRequestReceivedModal(null);
      fetchOfficeRequest();
    }
    if (messageRes) setMessage(messageRes);
    if (error) setErrors(error);
    setLoading(false);
  };

  if (fetching && !office) return <Loader />;
  if (!fetching && !office && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );
  if (office) {
    return (
      <div className="row user-details-view position-relative">
        {fetching && <Loader />}
        {displayDeleteModal && (
          <GenericModalDelete
            value={office}
            title="Usuwanie urzędu"
            content={(value) => <div>Czy na pewno chcesz usunąć urząd - {value?.name}?</div>}
            onDeleteClick={(value) => deleteOfficeAction(value.id)}
            isOpen={displayDeleteModal}
            toggle={() => setDisplayDeleteModal(false)}
            loading={loading}
            message={message}
            resetMessage={() => setMessage(null)}
          />
        )}
        {displayExecutorRequestReceivedModal && (
          <ExecutorRequestReceivedModal
            isOpen={!!displayExecutorRequestReceivedModal}
            loading={loading}
            onExecutorRequestReceived={executorRequestReceivedRequest}
            toggle={() => setDisplayExecutorRequestReceivedModal(null)}
            message={message}
            errors={errors}
          />
        )}
        <DetailsComponent
          office={office}
          onDeleteClick={displayDeleteModalAction}
          showExecutorRequestReceivedModal={(request_id: number) => setDisplayExecutorRequestReceivedModal(request_id)}
        />
      </div>
    );
  }

  return null;
};

export default Details;
