import React from 'react';
import DetailsBasic from 'modules/Office/component/Details/Office/Basic';
import Office from 'modules/Office/model/Office';
import DetailsExecutorsRequestInfo from 'modules/Office/component/Details/Office/ExecutorsRequestInfo';
import '../index.scss';
import { ROUTE_OFFICE_EXTENDED_UPDATE } from 'modules/Office/routes';
import DetailsExtendedExecutorsRequestList from '../ExtendedExecutorsRequestList';

type Props = {
  extended_office?: Office;
  onDeleteClick?: () => void;
  showExtendedExecutorRequestReceivedModal?: (request_id: number) => void;
};

const DetailsExtendedOffice: React.FC<Props> = ({
  extended_office,
  onDeleteClick,
  showExtendedExecutorRequestReceivedModal
}) => {
  return (
    <div className="col-12 office-details-component">
      <div className="row">
        <div className="col-lg-6 card-lg-wrapper-left">
          <DetailsBasic
            office={extended_office}
            onDeleteClick={onDeleteClick}
            updateEndpoint={ROUTE_OFFICE_EXTENDED_UPDATE}
          />
        </div>
        <div className="col-lg-6 card-lg-wrapper-right">
          <DetailsExecutorsRequestInfo office={extended_office} />
        </div>
        {showExtendedExecutorRequestReceivedModal && (
          <div className="col-lg-12">
            <DetailsExtendedExecutorsRequestList
              extended_office={extended_office}
              showExtendedExecutorRequestReceivedModal={showExtendedExecutorRequestReceivedModal}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailsExtendedOffice;
