import React from 'react';
import './index.scss';
// eslint-disable-next-line import/no-duplicates
import ExecutorExtendedRequestDetailsBasic from './Basic';
import { OfficeExtendedExecutorRequest } from '../../../type';

type Props = {
  extendedExecutorRequest: OfficeExtendedExecutorRequest;
  downloadFileRequest?: (fileId: number | string) => Promise<void>;
};

const ExtendedExecutorRequestDetailsComponent: React.FC<Props> = ({ extendedExecutorRequest, downloadFileRequest }) => {
  return (
    <div className="col-lg-9 user-data">
      <div className="user-data-card">
        <ExecutorExtendedRequestDetailsBasic
          downloadFileRequest={downloadFileRequest}
          officeExtendedRequest={extendedExecutorRequest}
        />
      </div>
    </div>
  );
};

export default ExtendedExecutorRequestDetailsComponent;
