import React from 'react';
import InformationList, { InformationField } from 'modules/Layout/component/InformationList';
import { OfficeExtendedExecutorRequest } from '../../../../type';
import { formatDate, ifContainsDateFormat } from '../../../../../Shared/helper/utils';

export interface Props {
  officeExtendedRequest: OfficeExtendedExecutorRequest;
}

const ExtendedExecutorRequestBasicDataset: React.FC<Props> = ({ officeExtendedRequest }) => {
  const fields: InformationField[] = [
    {
      label: 'Nazwa organu',
      value: officeExtendedRequest?.name
    },
    {
      label: 'Kategoria organu',
      value: officeExtendedRequest?.category.name
    },
    {
      label: 'Status odpowiedzi',
      value: officeExtendedRequest?.status.name
    },
    {
      label: 'Kanał dostarczenia odpowiedzi',
      value: officeExtendedRequest?.channel?.name
    },
    {
      label: 'Treść otrzymania odpowiedzi',
      value: officeExtendedRequest?.answer_content
    },
    {
      label: 'Data wysłania wniosku',
      value: ifContainsDateFormat(officeExtendedRequest?.sent_at, formatDate)
    },
    {
      label: 'Data otrzymania odpowiedzi',
      value: ifContainsDateFormat(officeExtendedRequest?.received_at, formatDate)
    }
  ];

  return <InformationList fields={fields} />;
};

export default ExtendedExecutorRequestBasicDataset;
