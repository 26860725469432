import CardBox from 'modules/Layout/component/CardBox';
import ExecutorDataClientDataset from 'modules/Executor/component/Dataset/Client/ExecutorData';
import React, { useState } from 'react';
import ExecutorClient from 'modules/Executor/model/ExecutorClient';
import MainDataClientDataset from 'modules/Executor/component/Dataset/Client/MainData';
import {
  BigRiggingItem,
  CommonDataItem,
  COMPLAINT_SLUG,
  ComplaintDataItem,
  MISREPRESENTATION_SLUG,
  MisrepresentationDataItem,
  OrderEntity,
  PENALTIES_SLUG,
  PenaltyDataItem,
  TERMINATION_SLUG,
  BID_RIGGING_SLUG,
  UNFAIR_COMPETITION_SLUG
} from 'modules/ExecutorOrder/model/Order';
import ExecutorDataClientPenaltiesDataset from 'modules/Executor/component/Dataset/Client/Violations/Penalties';
import ExecutorDataClientTerminationDataset from 'modules/Executor/component/Dataset/Client/Violations/Termination';
import ExecutorDataClientMisrepresentationDataset from 'modules/Executor/component/Dataset/Client/Violations/Misrepresentation';
import ExecutorDataClientComplaintDataset from 'modules/Executor/component/Dataset/Client/Violations/Complaint';
import Disclaimer from 'modules/Executor/component/Dataset/Client/Disclaimer';
import DisplayExecutorOrderList from 'modules/Executor/container/DisplayExecutorOrderList';
import DownloadExecutorPdf from 'modules/Executor/component/DownloadPdf';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import './index.scss';
import QuestionMarkIcon from 'modules/Layout/component/Icon/QuestionMark';
import DisplayPopovers from 'modules/Layout/component/Popover/DisplayWrapper';
import Accordion from '../../../../Layout/component/Accordion';
import ExecutorDataClientBidRigginDataset from '../../Dataset/Client/Violations/BidRiggin';
import ExecutorDataClientUnfairCompetitionDataset from '../../Dataset/Client/Violations/UnfairCompetition';
import ExecutorContractsTable from '../ContractsList';
import { ExecutorViolationSlug, getViolationWysiwygTitle } from '../../../model/Violation';
import ExecutorViolationDataset from '../../Dataset/Client/Violations/ExecutorViolation';
import ExecutorContractsAllTable from '../ContractsAllList';
import ExecutorCRBRTable from '../CRBRList';
import ExecutorConnectionsTable from '../ConnectionsList';
import FinancialReportsTable from '../FinancialReportsList';
import fetchFileRequest from '../../../../Shared/helper/APIRequests/fetchFileRequest';
import { fetchExecutorFinancialReportFile } from '../../../../ExecutorOrder/repository';
import { Message } from '../../../../Shared/type';
import useCancelToken from '../../../../Shared/helper/hooks/useCancelToken';
import { addToastAction } from '../../../../Layout/action';
import { orderFileDownloadToastSuccess } from '../../../../ExecutorOrder/toasts';
import { useDispatch } from 'react-redux';
import serverErrorToast from '../../../../Shared/action/errorToast';

const NO_VIOLATIONS_TEXT = `Brak danych w Raporcie, odnoszących się do danego przedsiębiorcy nie przesądza o braku wystąpienia okoliczności mogących być przedmiotem danych umieszczanych w Raporcie i nie przesądza o braku podstaw do podjęcia w stosunku do niego środków prawnych przez Użytkownika.`;

export interface Props {
  executor: ExecutorClient;
  setExecutorExplanationModal: (slug: string) => void;
  isReportClaim?: boolean;
  id?: string | number;
}

const segregateValidations = (orders: OrderEntity[] = []) => {
  let penaltiesOrderItems: PenaltyDataItem[] = [];
  let terminationOrderItems: CommonDataItem[] = [];
  let complaintOrderItems: ComplaintDataItem[] = [];
  let misrepresentationOrderItems: MisrepresentationDataItem[] = [];
  let bidRiggingOrderItems: BigRiggingItem[] = [];
  let unfairCompetitionsOrderItems: CommonDataItem[] = [];

  if (orders.length) {
    orders.forEach((order) => {
      if (order.penalties_checked) {
        penaltiesOrderItems = [
          ...penaltiesOrderItems,
          ...(Array.isArray(order.penalties) ? order.penalties : [order.penalties])
        ];
      }
      if (order.termination_checked) {
        terminationOrderItems = [
          ...terminationOrderItems,
          ...(Array.isArray(order.termination) ? order.termination : [order.termination])
        ];
      }
      if (order.complaint_checked) {
        complaintOrderItems = [
          ...complaintOrderItems,
          ...(Array.isArray(order.complaint) ? order.complaint : [order.complaint])
        ];
      }
      if (order.misrepresentation_checked) {
        misrepresentationOrderItems = [
          ...misrepresentationOrderItems,
          ...(Array.isArray(order.misrepresentation) ? order.misrepresentation : [order.misrepresentation])
        ];
      }
      if (order.bid_rigging_checked) {
        bidRiggingOrderItems = [
          ...bidRiggingOrderItems,
          ...(Array.isArray(order.bid_rigging) ? order.bid_rigging : [order.bid_rigging])
        ];
      }
      if (order.unfair_competition_checked) {
        unfairCompetitionsOrderItems = [
          ...unfairCompetitionsOrderItems,
          ...(Array.isArray(order.unfair_competition) ? order.unfair_competition : [order.unfair_competition])
        ];
      }
    });
  }

  return {
    penaltiesOrderItems,
    terminationOrderItems,
    complaintOrderItems,
    misrepresentationOrderItems,
    bidRiggingOrderItems,
    unfairCompetitionsOrderItems
  };
};

const haveAnyViolations = (
  penaltiesOrder: PenaltyDataItem[],
  terminationOrder: CommonDataItem[],
  complaintOrder: ComplaintDataItem[],
  misrepresentationOrder: MisrepresentationDataItem[],
  bidRiggingOrder: BigRiggingItem[],
  unfairCompetitionOrder: CommonDataItem[]
) => {
  return !(
    penaltiesOrder.length !== 0 ||
    terminationOrder.length !== 0 ||
    complaintOrder.length !== 0 ||
    bidRiggingOrder.length !== 0 ||
    unfairCompetitionOrder.length !== 0 ||
    misrepresentationOrder.length !== 0
  );
};

const ExecutorDetails: React.FC<Props> = ({ executor, setExecutorExplanationModal, isReportClaim, id }) => {
  const dispatch = useDispatch();
  const {
    penaltiesOrderItems,
    terminationOrderItems,
    complaintOrderItems,
    misrepresentationOrderItems,
    bidRiggingOrderItems,
    unfairCompetitionsOrderItems
  } = segregateValidations(executor.orders);
  const [loadingFile, setLoadingFile] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const cancelToken = useCancelToken();

  const dispatchFinancialReportFileDownloadToast = () => dispatch(addToastAction(orderFileDownloadToastSuccess()));
  const dispatchErrorMessageToast = (errMessage: string) =>
    dispatch(addToastAction(serverErrorToast(errMessage, 'Błąd zarządzania plikami')));

  const haveAnyViolationsStatus = haveAnyViolations(
    penaltiesOrderItems,
    terminationOrderItems,
    complaintOrderItems,
    misrepresentationOrderItems,
    bidRiggingOrderItems,
    unfairCompetitionsOrderItems
  );

  const executorFinancialReportDownloadFileRequest = async (financial_report_id: number | string) => {
    if (loadingFile) return;

    if (message) setMessage(null);

    setLoadingFile(true);
    const {
      cancelled,
      message: messageRes,
      success
    } = await fetchFileRequest(fetchExecutorFinancialReportFile, id, financial_report_id, cancelToken);

    if (cancelled) return;
    if (success) dispatchFinancialReportFileDownloadToast();
    if (messageRes) {
      dispatchErrorMessageToast(messageRes.value);
    }
    setLoadingFile(false);
  };

  return (
    <div className="executor-details-client">
      <CardBox heading="Raport Asystent Postępowania">
        <p className="h3 mb-0">
          Sytuacja <span style={{ color: '#b7342e' }}>{executor?.executor_data?.name}</span> na gruncie prawa zamówień
          publicznych
        </p>
        {haveAnyViolationsStatus && (
          <div className="d-flex align-items-center mt-2 mb-0">
            <p className="h4 mr-1">Nie stwierdzono ewentualnych naruszeń</p>
            <DisplayPopovers
              popoverId="PopoverSub1"
              popoverContent={NO_VIOLATIONS_TEXT}
              className="tooltip-light subscriptions-tooltip-description"
            />
          </div>
        )}
      </CardBox>
      <CardBox
        heading="Informacje o zleceniu"
        paddingRight="30px"
        paddingBottom="0"
        actions={<DownloadExecutorPdf isReportClaim={isReportClaim} executorId={id || executor?.executor_data?.id} />}
      >
        <MainDataClientDataset executor={executor} />
      </CardBox>
      <CardBox heading="Dane weryfikowanego wykonawcy" paddingRight="30x" paddingBottom="0">
        <ExecutorDataClientDataset executor={executor} />
      </CardBox>
      <CardBox
        heading="Informacja o nienależytym wykonaniu umów z BZP"
        headerElements={
          <DisplayPopovers
            popoverId="PopoverSub2s"
            popoverContent="DANE DOTYCZĄCE OKRESU OD 01.01.2021"
            className="tooltip-light subscriptions-tooltip-description"
          />
        }
        paddingRight="30x"
      >
        <ExecutorContractsTable contracts={executor.contracts} />
      </CardBox>
      <CardBox
        heading="Lista wygranych przetargów"
        headerElements={
          <DisplayPopovers
            popoverId="PopoverSub3s"
            popoverContent="DANE DOTYCZĄCE OKRESU OD 01.01.2021"
            className="tooltip-light subscriptions-tooltip-description"
          />
        }
        paddingRight="30x"
      >
        <ExecutorContractsAllTable contracts={executor.contracts_all} />
      </CardBox>
      <CardBox
        heading={`Naliczenie kar umownych ${
          penaltiesOrderItems.length > 0 ? ` - (${penaltiesOrderItems.length})` : ' - Nie dotyczy'
        }`}
        headerElements={
          <ActionUpdate
            className="d-sm-inline d-none ml-1"
            title="Wyjaśnienie sekcji"
            label={<QuestionMarkIcon height="18px" className="question-mark-icon-executors" />}
            onClick={() => setExecutorExplanationModal(PENALTIES_SLUG)}
          />
        }
      >
        {penaltiesOrderItems.length > 0 &&
          executor.orders.map(
            (order) =>
              order.penalties.length > 0 && (
                <Accordion
                  accordionContentStyle={{ padding: '0' }}
                  entity={{
                    title: order.display_name,
                    content: (
                      <>
                        {order.penalties.map((penalty, index, arr) => (
                          <div
                            className={`pzpeu-table-list inside-card-box${
                              index + 1 === arr.length ? '' : ' violations-divider'
                            }`}
                            style={{ margin: 0 }}
                            key={penalty?.id}
                          >
                            <DisplayExecutorOrderList
                              orderFiles={penalty?.files}
                              sectionId={penalty.id}
                              orderListStyle={index === 0 ? {} : { paddingTop: '34px' }}
                            >
                              <ExecutorDataClientPenaltiesDataset order={order} penalty={penalty} />
                            </DisplayExecutorOrderList>
                          </div>
                        ))}
                      </>
                    )
                  }}
                />
              )
          )}
      </CardBox>
      <CardBox
        heading={`Rozwiązanie umowy ${
          terminationOrderItems.length > 0 ? ` - (${terminationOrderItems.length})` : ' - Nie dotyczy'
        }`}
        headerElements={
          <ActionUpdate
            className="d-sm-inline d-none ml-1"
            title="Wyjaśnienie sekcji"
            label={<QuestionMarkIcon height="18px" className="question-mark-icon-executors" />}
            onClick={() => setExecutorExplanationModal(TERMINATION_SLUG)}
          />
        }
      >
        {terminationOrderItems.length > 0 &&
          executor.orders.map(
            (order) =>
              order.termination.length > 0 && (
                <Accordion
                  accordionContentStyle={{ padding: '0' }}
                  entity={{
                    title: order.display_name,
                    content: (
                      <>
                        {order.termination.map((terminationItem, index, arr) => (
                          <div
                            className={`pzpeu-table-list inside-card-box${
                              index + 1 === arr.length ? '' : ' violations-divider'
                            }`}
                            style={{ margin: 0 }}
                            key={terminationItem?.id}
                          >
                            <DisplayExecutorOrderList
                              orderFiles={terminationItem?.files}
                              sectionId={terminationItem.id}
                              orderListStyle={index === 0 ? {} : { paddingTop: '34px' }}
                            >
                              <ExecutorDataClientTerminationDataset order={order} terminationItem={terminationItem} />
                            </DisplayExecutorOrderList>
                          </div>
                        ))}
                      </>
                    )
                  }}
                />
              )
          )}
      </CardBox>
      <CardBox
        heading={`Wytoczenie powództwa w związku z realizacją umowy ${
          complaintOrderItems.length > 0 ? ` - (${complaintOrderItems.length})` : ' - Nie dotyczy'
        }`}
        headerElements={
          <ActionUpdate
            className="d-sm-inline d-none ml-1"
            title="Wyjaśnienie sekcji"
            label={<QuestionMarkIcon height="18px" className="question-mark-icon-executors" />}
            onClick={() => setExecutorExplanationModal(COMPLAINT_SLUG)}
          />
        }
      >
        {complaintOrderItems.length > 0 &&
          executor.orders.map(
            (order) =>
              order.complaint.length > 0 && (
                <Accordion
                  accordionContentStyle={{ padding: '0' }}
                  entity={{
                    title: order.display_name,
                    content: (
                      <>
                        {order.complaint.map((complaintItem, index, arr) => (
                          <div
                            className={`pzpeu-table-list inside-card-box${
                              index + 1 === arr.length ? '' : ' violations-divider'
                            }`}
                            style={{ margin: 0 }}
                            key={complaintItem?.id}
                          >
                            <DisplayExecutorOrderList
                              orderFiles={complaintItem?.files}
                              sectionId={complaintItem.id}
                              orderListStyle={index === 0 ? {} : { paddingTop: '34px' }}
                            >
                              <ExecutorDataClientComplaintDataset order={order} complaint={complaintItem} />
                            </DisplayExecutorOrderList>
                          </div>
                        ))}
                      </>
                    )
                  }}
                />
              )
          )}
      </CardBox>
      <CardBox
        heading={`Wprowadzenie zamawiającego w błąd ${
          misrepresentationOrderItems.length > 0 ? ` - (${misrepresentationOrderItems.length})` : ' - Nie dotyczy'
        }`}
        headerElements={
          <ActionUpdate
            className="d-sm-inline d-none ml-1"
            title="Wyjaśnienie sekcji"
            label={<QuestionMarkIcon height="18px" className="question-mark-icon-executors" />}
            onClick={() => setExecutorExplanationModal(MISREPRESENTATION_SLUG)}
          />
        }
      >
        {misrepresentationOrderItems.length > 0 &&
          executor.orders.map(
            (order) =>
              order.misrepresentation.length > 0 && (
                <Accordion
                  accordionContentStyle={{ padding: '0' }}
                  entity={{
                    title: order.display_name,
                    content: (
                      <>
                        {order.misrepresentation.map((misrepresentationItem, index, arr) => (
                          <div
                            className={`pzpeu-table-list inside-card-box${
                              index + 1 === arr.length ? '' : ' violations-divider'
                            }`}
                            style={{ margin: 0 }}
                            key={misrepresentationItem?.id}
                          >
                            <DisplayExecutorOrderList
                              orderFiles={misrepresentationItem?.files}
                              sectionId={misrepresentationItem.id}
                              orderListStyle={index === 0 ? {} : { paddingTop: '34px' }}
                            >
                              <ExecutorDataClientMisrepresentationDataset
                                order={order}
                                misrepresentation={misrepresentationItem}
                              />
                            </DisplayExecutorOrderList>
                          </div>
                        ))}
                      </>
                    )
                  }}
                />
              )
          )}
      </CardBox>
      <CardBox
        heading={`Zmowy przetargowe ${
          bidRiggingOrderItems.length > 0 ? ` - (${bidRiggingOrderItems.length})` : ' - Nie dotyczy'
        }`}
        headerElements={
          <ActionUpdate
            className="d-sm-inline d-none ml-1"
            title="Wyjaśnienie sekcji"
            label={<QuestionMarkIcon height="18px" className="question-mark-icon-executors" />}
            onClick={() => setExecutorExplanationModal(BID_RIGGING_SLUG)}
          />
        }
      >
        {bidRiggingOrderItems.length > 0 &&
          executor.orders.map(
            (order) =>
              order.bid_rigging.length > 0 && (
                <Accordion
                  accordionContentStyle={{ padding: '0' }}
                  entity={{
                    title: order.display_name,
                    content: (
                      <>
                        {order.bid_rigging.map((bidRiggingItem, index, arr) => (
                          <div
                            className={`pzpeu-table-list inside-card-box${
                              index + 1 === arr.length ? '' : ' violations-divider'
                            }`}
                            style={{ margin: 0 }}
                            key={bidRiggingItem?.id}
                          >
                            <DisplayExecutorOrderList
                              orderFiles={bidRiggingItem?.files}
                              sectionId={bidRiggingItem.id}
                              orderListStyle={index === 0 ? {} : { paddingTop: '34px' }}
                            >
                              <ExecutorDataClientBidRigginDataset order={order} bidRiggingItem={bidRiggingItem} />
                            </DisplayExecutorOrderList>
                          </div>
                        ))}
                      </>
                    )
                  }}
                />
              )
          )}
      </CardBox>
      <CardBox
        heading={`Czyny nieuczciwej konkurencji ${
          unfairCompetitionsOrderItems.length > 0 ? ` - (${unfairCompetitionsOrderItems.length})` : ' - Nie dotyczy'
        }`}
        headerElements={
          <ActionUpdate
            className="d-sm-inline d-none ml-1"
            title="Wyjaśnienie sekcji"
            label={<QuestionMarkIcon height="18px" className="question-mark-icon-executors" />}
            onClick={() => setExecutorExplanationModal(UNFAIR_COMPETITION_SLUG)}
          />
        }
      >
        {unfairCompetitionsOrderItems.length > 0 &&
          executor.orders.map(
            (order) =>
              order.unfair_competition.length > 0 && (
                <Accordion
                  accordionContentStyle={{ padding: '0' }}
                  entity={{
                    title: order.display_name,
                    content: (
                      <>
                        {order.unfair_competition.map((unfairCompetitionItem, index, arr) => (
                          <div
                            className={`pzpeu-table-list inside-card-box${
                              index + 1 === arr.length ? '' : ' violations-divider'
                            }`}
                            style={{ margin: 0 }}
                            key={unfairCompetitionItem?.id}
                          >
                            <DisplayExecutorOrderList
                              orderFiles={unfairCompetitionItem?.files}
                              sectionId={unfairCompetitionItem.id}
                              orderListStyle={index === 0 ? {} : { paddingTop: '34px' }}
                            >
                              <ExecutorDataClientUnfairCompetitionDataset
                                order={order}
                                unfairCompetitionItem={unfairCompetitionItem}
                              />
                            </DisplayExecutorOrderList>
                          </div>
                        ))}
                      </>
                    )
                  }}
                />
              )
          )}
      </CardBox>
      {!!executor.other_violations &&
        Object.entries(executor.other_violations).map(([key, violationValue]) => (
          <CardBox
            key={key}
            heading={`${getViolationWysiwygTitle(key as ExecutorViolationSlug)} ${
              violationValue.length > 0 ? ` - (${violationValue.length})` : ' - Nie dotyczy'
            }`}
            headerElements={
              <ActionUpdate
                className="d-sm-inline d-none ml-1"
                title="Wyjaśnienie sekcji"
                label={<QuestionMarkIcon height="18px" className="question-mark-icon-executors" />}
                onClick={() => setExecutorExplanationModal(key)}
              />
            }
          >
            {violationValue.length > 0 &&
              violationValue.map((violation) => (
                <Accordion
                  key={violation.id}
                  accordionContentStyle={{ padding: '0' }}
                  entity={{
                    title: violation.authority_name,
                    content: (
                      <div className="pzpeu-table-list inside-card-box" style={{ margin: 0 }}>
                        <DisplayExecutorOrderList
                          orderFiles={violation?.files}
                          sectionId={violation.id}
                          orderListStyle={{ paddingTop: '34px' }}
                        >
                          <ExecutorViolationDataset violation={violation} />
                        </DisplayExecutorOrderList>
                      </div>
                    )
                  }}
                />
              ))}
          </CardBox>
        ))}
      {!!executor.crbr && (
        <CardBox heading="Lista beneficjentów rzeczywistych" paddingRight="30x">
          <ExecutorCRBRTable crbr={executor.crbr} />
        </CardBox>
      )}
      {!!executor.connections && (
        <CardBox heading="Powiązania organizacji" paddingRight="30x">
          <ExecutorConnectionsTable connections={executor.connections} />
        </CardBox>
      )}
      {!!executor.financial_reports && (
        <CardBox heading="Sprawozdania finansowe z KRS" paddingRight="30x">
          <FinancialReportsTable
            downloadFile={executorFinancialReportDownloadFileRequest}
            financial_reports={executor.financial_reports}
          />
        </CardBox>
      )}
      <CardBox heading="Nota prawna" paddingRight="30px">
        <Disclaimer />
      </CardBox>
    </div>
  );
};

export default ExecutorDetails;
