import { OrderEntity } from 'modules/ExecutorOrder/model/Order';
import { ExecutorFinancialReport, ExecutorViolation, ExecutorViolationSlug } from './Violation';

export type ExecutorClientList = {
  id?: number;
  name?: string;
  vies_exist?: number | string;
  white_list_vat_exist?: number;
  last_verification_date?: string;
  first_name?: string;
  last_name?: string;
  street?: string;
  postal_code?: string;
  city?: string;
  post_office?: string;
  nip?: string;
  krs?: string;
  penalties_total_value?: number;
  problems?: {
    penalties?: boolean;
    termination?: boolean;
    complaint?: boolean;
    misrepresentation?: boolean;
  };
  expires_at?: string;
};

export type ExecutorData = {
  id?: number;
  name?: string;
  vies_exist?: number | string;
  white_list_vat_exist?: number;
  last_verification_date?: string;
  regon_status?: string;
  bankruptcy_info?: string;
  consumer_bankruptcy_info?: string;
  first_name?: string;
  last_name?: string;
  street?: string;
  postal_code?: string;
  city?: string;
  post_office?: string;
  nip?: string;
  email?: string;
  phone?: string;
  krs?: string;
  legal_form?: number;
  business_start_date?: string;
  voivodeship?: string;
};

export type ExecutorContract = {
  order_object?: string;
  organization_name?: string;
  organization_city?: string;
  notice_date?: string;
  organization_national_id?: string;
};

export type ExecutorCRBR = {
  firstname?: string;
  lastname?: string;
  birth_date?: string;
};

export type ExecutorConnection = {
  name?: string;
  connection_qnt?: number;
  connection_types?: string[];
};

export type ExecutorContractAll = {
  order_object?: string;
  organization_name?: string;
  organization_city?: string;
  notice_date?: string;
  organization_national_id?: string;
  type?: string;
  contract_properly_performed?: boolean;
};

export type ExecutorMainData = {
  payment_number?: number;
  name?: string;
  vies_exist?: number | string;
  white_list_vat_exist?: number;
  email?: string;
  date?: string;
  executor_updated_at?: string;
  applications_count?: number;
  executors_count?: number;
  executors_with_penalties_count?: number;
  executors_with_termination_count?: number;
  executors_with_complaint_count?: number;
  executors_with_misrepresentation_count?: number;
  language?: string;
};

export interface ExecutorClientEntity extends ExecutorClientList {
  executor_data?: ExecutorData;
  main_data?: ExecutorMainData;
  orders?: OrderEntity[];
  other_violations?: Record<ExecutorViolationSlug, ExecutorViolation[]>;
  contracts?: ExecutorContract[];
  contracts_all?: ExecutorContractAll[];
  crbr?: ExecutorCRBR[];
  connections?: ExecutorConnection[];
  financial_reports?: ExecutorFinancialReport[];
}

export default class ExecutorClient {
  readonly executor_data?: ExecutorData;
  readonly main_data?: ExecutorMainData;
  readonly orders?: OrderEntity[];
  readonly other_violations?: Record<ExecutorViolationSlug, ExecutorViolation[]>;
  readonly contracts?: ExecutorContract[];
  readonly contracts_all?: ExecutorContractAll[];
  readonly crbr?: ExecutorCRBR[];
  readonly connections?: ExecutorConnection[];
  readonly financial_reports?: ExecutorFinancialReport[];

  readonly id?: number;
  readonly name?: string;
  readonly vies_exist?: number | string;
  readonly white_list_vat_exist?: number;
  readonly first_name?: string;
  readonly last_name?: string;
  readonly street?: string;
  readonly postal_code?: string;
  readonly city?: string;
  readonly post_office?: string;
  readonly nip?: string;
  readonly krs?: string;
  readonly penalties_total_value?: number;
  readonly expires_at?: string;
  readonly problems?: {
    penalties?: boolean;
    termination?: boolean;
    complaint?: boolean;
    misrepresentation?: boolean;
  };

  constructor(entity: ExecutorClientEntity) {
    this.executor_data = entity.executor_data;
    this.main_data = entity.main_data;
    this.orders = entity.orders;
    this.other_violations = entity.other_violations;
    this.contracts = entity.contracts;
    this.contracts_all = entity.contracts_all;
    this.connections = entity.connections;
    this.crbr = entity.crbr;
    this.financial_reports = entity.financial_reports;

    this.id = entity.id;
    this.first_name = entity.first_name;
    this.name = entity.name;
    this.vies_exist = entity.vies_exist;
    this.white_list_vat_exist = entity.white_list_vat_exist;
    this.last_name = entity.last_name;
    this.nip = entity.nip;
    this.krs = entity.krs;
    this.city = entity.city;
    this.street = entity.street;
    this.postal_code = entity.postal_code;
    this.post_office = entity.post_office;
    this.penalties_total_value = entity.penalties_total_value;
    this.problems = entity.problems;
    this.expires_at = entity.expires_at;
  }

  static getAdminFilterableAttributes(): string[] {
    return ['name', 'first_name', 'last_name', 'street', 'postal_code', 'city', 'post_office', 'nip'];
  }

  static getFilterableAttributes(): string[] {
    return [...this.getAdminFilterableAttributes(), 'penalties_total_value_min', 'penalties_total_value_max'];
  }

  static getAdminSortableAttributes(): string[] {
    return ['name', 'first_name', 'last_name', 'street', 'postal_code', 'city', 'post_office', 'nip'];
  }

  static getSortableAttributes(): string[] {
    return [...this.getAdminSortableAttributes(), 'penalties_total_value'];
  }
}

export const createExecutorClient = (entity: ExecutorClientEntity): ExecutorClient => new ExecutorClient(entity);
