import { Message, ValidationErrors } from 'modules/Shared/type';
import React, { useEffect, useState } from 'react';
import { Button, Form as FormStrap } from 'reactstrap';
import { Link } from 'react-router-dom';
import { OfficeExecutorRequestChannelsValue, OfficeExecutorRequestsState } from '../../type';
import fetchRequest from '../../../Shared/helper/APIRequests/fetchRequest';
import { fetchExecutorRequestChannels } from '../../repository';
import useCancelToken from '../../../Shared/helper/hooks/useCancelToken';
import { formatDateValue } from '../../../Shared/helper/utils';
import Loader from '../../../Layout/component/Loader';
import Alert from '../../../Layout/component/Alert';
import ExtendedExecutorRequestFieldset from '../ExtendedExecutorRequestFieldset';

export interface Props {
  extendedExecutorRequest: OfficeExecutorRequestsState;
  errors?: ValidationErrors;
  disabled?: boolean;
  cancelRedirect?: string;
  originalFiles?: number;
  momentFormat?: string;
  submit: (extendedExecutorRequestData: OfficeExecutorRequestsState) => void;
}

const ExtendedExecutorRequestForm: React.FC<Props> = ({
  extendedExecutorRequest,
  errors,
  disabled,
  submit,
  momentFormat,
  cancelRedirect,
  originalFiles
}) => {
  const [channelValues, setChannelValues] = useState<OfficeExecutorRequestChannelsValue[]>([]);
  const [channelFetching, setChannelFetching] = useState(false);
  const [channelMessage, setChannelMessage] = useState<Message>(null);
  const [values, setValue] = useState<OfficeExecutorRequestsState>({
    name: extendedExecutorRequest?.name ?? '',
    status: extendedExecutorRequest?.status ?? undefined,
    category: extendedExecutorRequest?.category ?? undefined,
    sent_at: extendedExecutorRequest?.sent_at ?? formatDateValue(momentFormat),
    received_at: extendedExecutorRequest?.received_at ?? formatDateValue(momentFormat),
    channel: extendedExecutorRequest?.channel ?? undefined,
    answer_content: extendedExecutorRequest?.answer_content ?? '',
    files: extendedExecutorRequest?.files ?? []
  });

  console.log(values.files);

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setChannelFetching(true);
      const {
        data,
        cancelled,
        message: messageResponse
      } = await fetchRequest(fetchExecutorRequestChannels, cancelToken);

      if (cancelled) return;
      if (data) setChannelValues(data.data);
      if (messageResponse) setChannelMessage(messageResponse);
      setChannelFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submit(values);
  };

  const hasValue = () => !Object.values(values).some(Boolean);

  return (
    <>
      {channelFetching && <Loader />}
      {channelMessage && <Alert message={channelMessage} />}
      <FormStrap onSubmit={onSubmit}>
        <ExtendedExecutorRequestFieldset
          disabled={disabled}
          values={values}
          errors={errors}
          originalFiles={originalFiles}
          setValues={setValue}
          channelValues={channelValues}
          required
        />
        <div className="form-actions">
          <Button
            type="submit"
            color="primary"
            className="pzpeu-btn-disabled waves-effect waves-light"
            disabled={hasValue() || disabled}
          >
            Zapisz
          </Button>
          <Link to={cancelRedirect} className="cancel waves-effect waves-light">
            Anuluj
          </Link>
        </div>
      </FormStrap>
    </>
  );
};

export default ExtendedExecutorRequestForm;
