import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import { OfficeExecutorRequestsValue } from 'modules/Office/type';
import Authorize from 'modules/Auth/container/Authorize';
import { Link } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import { OFFICES_DETAILS } from 'modules/Office/permissions';
import { ROUTE_EXECUTOR_REQUEST } from 'modules/Office/routes';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import { formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import TooltipPlusIcon from 'modules/Layout/component/Icon/TooltipPlus';

type Props = {
  executorsRequestList?: OfficeExecutorRequestsValue[];
  officeId?: number;
  showModal?: (request_id: number) => void;
};

const ExecutorsRequestListTable: React.FC<Props> = ({ executorsRequestList, officeId: id, showModal }) => {
  const redirectLink = (value: string, request_id: number) => (
    <Authorize permissions={[OFFICES_DETAILS]} fallback={value}>
      <Link to={getPathUrl(ROUTE_EXECUTOR_REQUEST, { id, request_id })}>{value}</Link>
    </Authorize>
  );

  const cols: TableCol<OfficeExecutorRequestsValue>[] = [
    {
      property: 'response_status',
      label: 'Status odpowiedzi:',
      value: (row) => redirectLink(row?.received_at ? 'Otrzymano' : 'Oczekiwanie na odpowiedź', Number(row?.id))
    },
    {
      property: 'sent_at',
      label: 'Data wysłania zapytania:',
      value: (row) => redirectLink(ifContainsDateFormat(row?.sent_at, formatDatetimeSeconds), Number(row?.id))
    },

    {
      property: 'received_at',
      label: 'Data otrzymania odpowiedzi:',
      value: (row) => redirectLink(ifContainsDateFormat(row?.received_at, formatDatetimeSeconds), Number(row?.id))
    },
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            {!row.received_at ? (
              <Authorize permissions={[OFFICES_DETAILS]}>
                <ActionUpdate
                  title="Zatwierdź zapytanie"
                  label={<TooltipPlusIcon height="20px" />}
                  onClick={() => showModal(Number(row?.id))}
                />
              </Authorize>
            ) : null}
          </div>
        );
      }
    }
  ];

  return <Table cols={cols} rows={executorsRequestList} />;
};

export default ExecutorsRequestListTable;
