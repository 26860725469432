import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { managePageAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { fetchAlerts, fetchForeignProceedings } from 'modules/ForeignProceedings/repository';
import CardBox from 'modules/Layout/component/CardBox';
import ForeignProceedings from 'modules/ForeignProceedings/model/ForeignProceedings';
import ForeignProceedingsTable from 'modules/ForeignProceedings/container/ForeignProceedingsList/Table';
import { breadcrumbRouteForeignProceedingsAlertDetails } from '../../../breadcrumbs';
import AlertsDetails from '../../../component/Details/Alerts';
import { AlertFormData } from '../../../component/Form/AlertForm';

const AlertDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [alertData, setAlertData] = useState<AlertFormData>(null);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);
  const [foreignProceedingsList, setForeignProceedingsList] = useState<ForeignProceedings[]>(null);
  const [fetchingList, setFetchingList] = useState(false);

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setFetching(true);
      const {
        data: { data },
        cancelled,
        message: messageResponse
      } = await fetchRequest(fetchAlerts, cancelToken);

      if (cancelled) return;
      if (data) {
        const alertDetails = data.find((alertItem) => alertItem.id === Number(id));
        setAlertData(alertDetails);
      }
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (alertData) {
      const title = `Alert postępowania${alertData.name ? ` - ${alertData.name}` : ''}`;
      dispatch(
        managePageAction({
          title,
          breadcrumb: breadcrumbRouteForeignProceedingsAlertDetails(alertData.name, alertData.id)
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertData]);

  const fetchProceedings = useCallback(async () => {
    if (!alertData?.filters) return;

    setFetchingList(true);

    const { slug_source, ...otherFilters } = alertData?.filters;

    const filtersAsParams = {
      ...otherFilters,
      industry_type_id: alertData.filters.industry_sub_type_id
        ? alertData.filters.industry_sub_type_id
        : alertData.filters.industry_type_id,
      ...(Array.isArray(slug_source)
        ? slug_source.reduce((acc, slug, index) => {
            acc[`slug_source[${index}]`] = String(slug);
            return acc;
          }, {} as Record<string, string>)
        : {})
    };

    const {
      data: reqData,
      cancelled,
      message: messageResponse
    } = await fetchRequest(fetchForeignProceedings, filtersAsParams, cancelToken);

    if (cancelled) return;
    if (reqData) setForeignProceedingsList(reqData.data);
    if (messageResponse) setMessage(messageResponse);

    setFetchingList(false);
  }, [alertData?.filters, cancelToken]);

  useEffect(() => {
    // Call fetchProceedings directly in useEffect
    fetchProceedings().then((r) => r);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetching]);

  useEffect(() => {
    if (alertData?.filters && !fetchingList) {
      fetchProceedings().then((r) => r);
    }
  }, [alertData?.filters]);

  if (fetching) return <Loader />;
  if (!fetching && !alertData && message)
    return (
      <div className="executor-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <CardBox>{message && <Alert message={message} />}</CardBox>
        </div>
      </div>
    );

  if (!fetching && alertData) {
    return (
      <>
        <div className="row executor-details-view position-relative justify-content-center">
          <div className="col-12 col-lg-7 user-details">
            <AlertsDetails alertDetails={alertData} setAlert={setAlertData} />
          </div>
        </div>
        <div className="position-relative">
          {fetchingList && <Loader />}
          <ForeignProceedingsTable foreignProceedingsList={foreignProceedingsList ?? []} isAdmin={false} />
        </div>
      </>
    );
  }

  return null;
};

export default AlertDetails;
