import CardBox from 'modules/Layout/component/CardBox';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import './index.scss';
import { suffixLabel } from 'modules/Layout/helper/misc';
import Accordion from 'modules/Layout/component/Accordion';
import AlertsDataset from '../../Dataset/Alerts';
import { AlertFormData } from '../../Form/AlertForm';

export interface Props {
  alertDetails: AlertFormData;
  setAlert: (alert: AlertFormData) => void;
}

const AlertsDetails: React.FC<Props> = ({ alertDetails, setAlert }) => {
  return (
    <CardBox
      heading={alertDetails?.name}
      paddingLeft="30px"
      paddingRight="30px"
      paddingBottom="30px"
      className="imports-details"
    >
      <div style={{ marginBottom: '-20px' }}>
        <FormGroup>
          <Accordion
            entity={{
              title: 'Filtry',
              content: <AlertsDataset alertDetails={alertDetails} />
            }}
          />
          <FormGroup>
            <Label for="input-filters-published_date" className="font-14 pt-1">
              {suffixLabel('Data publikacji')}
            </Label>
            <Input
              type="date"
              name="filters.published_date"
              id="input-filters-published_date"
              placeholder="Data publikacji"
              value={alertDetails.filters?.published_date_from ?? ''}
              onChange={(event) =>
                setAlert({
                  ...alertDetails,
                  filters: {
                    ...alertDetails.filters,
                    published_date_from: event.target.value,
                    published_date_to: event.target.value
                  }
                })
              }
            />
          </FormGroup>
        </FormGroup>
      </div>
    </CardBox>
  );
};

export default AlertsDetails;
