import { ROUTE_LOGIN } from 'modules/Auth/routes';
import Alert from 'modules/Layout/component/Alert';
import Loader from 'modules/Layout/component/Loader';
import { Message } from 'modules/Shared/type';
import React, { ReactNode } from 'react';
import './style.scss';
import Logo from 'modules/Auth/component/Logo';
import CardBox from 'modules/Layout/component/CardBox';
import bsBanner from '../../view/Login/bs-banner.png';

export interface Props {
  busy: boolean;
  message?: Message;
  children: Array<Readonly<ReactNode>>;
  className?: string;
  logoSize?: number;
  withoutLinks?: boolean;
}

export const View: React.FC<Props> = (props: Props): JSX.Element => {
  const { busy, message, children, logoSize = 67, className = '', withoutLinks = false } = props;

  return (
    <div className={`pzpeu-view-page ${className}`}>
      <div className="pzpeu-view">
        <CardBox
          paddingLeft="56px"
          paddingRight="56px"
          paddingBottom="46px"
          paddingTop={process.env.THEME_TEMPLATE === 'bs' ? '0' : '57px'}
          childrenPaddingTop={process.env.THEME_TEMPLATE === 'bs' && '0'}
          className="position-relative"
        >
          {process.env.THEME_TEMPLATE === 'bs' && (
            <img
              style={{
                borderTopLeftRadius: '0.375rem',
                borderTopRightRadius: '0.375rem',
                marginBottom: '32px',
                width: '100%'
              }}
              src={bsBanner}
              alt="bs banner"
            />
          )}
          <div className="text-center">
            <Logo height={logoSize} />
          </div>
          <div className="view-children">
            {Boolean(children[0]) && <div className="view-title">{children[0]}</div>}
            <Alert message={message} />
            {Boolean(children[1]) && <div className="view-content">{children[1]}</div>}
          </div>
          {busy && <Loader />}
          {!withoutLinks && (
            <div className="text-center go-back-link">
              <a className="pzpeu-link" href={ROUTE_LOGIN}>
                Powrót do strony logowania
              </a>
            </div>
          )}
        </CardBox>
      </div>
    </div>
  );
};
