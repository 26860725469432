import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import { OfficeExecutorRequestsValue } from 'modules/Office/type';
import Authorize from 'modules/Auth/container/Authorize';
import { Link } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import { OFFICES_EXTENDED } from 'modules/Office/permissions';
import { ROUTE_EXTENDED_EXECUTOR_REQUEST, ROUTE_EXTENDED_EXECUTOR_REQUEST_UPDATE } from 'modules/Office/routes';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import EditIcon from '../../../../Layout/component/Icon/Edit';

type Props = {
  extendedExecutorRequestsList?: OfficeExecutorRequestsValue[];
  officeId?: number;
};

const ExtendedExecutorsRequestListTable: React.FC<Props> = ({ extendedExecutorRequestsList, officeId: id }) => {
  const redirectLink = (value: string, request_id: number) => (
    <Authorize permissions={[OFFICES_EXTENDED]} fallback={value}>
      <Link to={getPathUrl(ROUTE_EXTENDED_EXECUTOR_REQUEST, { id, request_id })}>{value}</Link>
    </Authorize>
  );

  const cols: TableCol<OfficeExecutorRequestsValue>[] = [
    {
      property: 'name',
      label: 'Nazwa organu',
      value: (row) => redirectLink(row?.name, Number(row?.id))
    },
    {
      property: 'category_slug',
      label: 'Kategoria organu',
      value: (row) => redirectLink(row?.category?.name, Number(row?.id))
    },
    {
      property: 'status',
      label: 'Status odpowiedzi',
      value: (row) => row?.status?.name
    },
    {
      property: 'sent_at',
      label: 'Data wysłania wniosku',
      value: (row) => ifContainsDateFormat(row?.sent_at, formatDate)
    },
    {
      property: 'received_at',
      label: 'Data otrzymania odpowiedzi',
      value: (row) => ifContainsDateFormat(row?.received_at, formatDate)
    },
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            {!row.received_at ? (
              <Authorize permissions={[OFFICES_EXTENDED]}>
                <ActionUpdate
                  className="mx-1"
                  title="Edycja danych podstawowych"
                  label={<EditIcon height="20px" />}
                  to={getPathUrl(ROUTE_EXTENDED_EXECUTOR_REQUEST_UPDATE, { id, request_id: row.id })}
                />
              </Authorize>
            ) : null}
          </div>
        );
      }
    }
  ];

  return <Table cols={cols} rows={extendedExecutorRequestsList} />;
};

export default ExtendedExecutorsRequestListTable;
