import Authorize from 'modules/Auth/container/Authorize';
import CardBox from 'modules/Layout/component/CardBox';
import Office from 'modules/Office/model/Office';
import { OFFICES_EDIT, OFFICES_DELETE } from 'modules/Office/permissions';
import { ROUTE_OFFICE_UPDATE } from 'modules/Office/routes';
import BasicOfficeDataset from 'modules/Office/component/Dataset/Basic';
import { getPathUrl } from 'modules/Shared/helper/api';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

export interface Props {
  office: Office;
  onDeleteClick?: () => void;
  updateEndpoint?: string;
}

const DetailsBasic: React.FC<Props> = ({ office, onDeleteClick, updateEndpoint }) => {
  return (
    <CardBox
      heading="Dane podstawowe"
      paddingBottom="0"
      paddingRight="30px"
      actions={
        <>
          <Authorize permissions={[OFFICES_EDIT]}>
            <Link
              className="link-with-border"
              to={{
                pathname: getPathUrl(updateEndpoint ?? ROUTE_OFFICE_UPDATE, { id: office.id }),
                state: { office }
              }}
            >
              EDYTUJ
            </Link>
          </Authorize>
          {onDeleteClick && (
            <Authorize permissions={[OFFICES_DELETE]}>
              <Button onClick={onDeleteClick} color="link" className="link-with-border p-0">
                USUŃ URZĄD
              </Button>
            </Authorize>
          )}
        </>
      }
    >
      <BasicOfficeDataset office={office} />
    </CardBox>
  );
};

export default DetailsBasic;
