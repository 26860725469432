import { Message } from 'modules/Shared/type';
import React from 'react';
import Alert from 'modules/Layout/component/Alert';
import ReturnButton from 'modules/Layout/component/Button/ReturnButton';
import useIsMobile from 'modules/Shared/helper/hooks/useIsMobile';
import './style.scss';

export interface CardBoxProps {
  heading?: React.ReactText;
  className?: string;
  headingClassName?: string;
  headingWrapperClassName?: string;
  childrenClassName?: string;
  actionsClassName?: string;
  actions?: React.ReactNode;
  headerElements?: React.ReactNode;
  redirectReturnBtn?: string;
  redirectReturnBtnBottom?: string;
  children?: React.ReactNode;
  message?: Message;
  paddingTop?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingBottom?: string;
  childrenPaddingTop?: string;
  headerTitleClassName?: string;
  form?: 'small';
  noBackground?: boolean;
}

const CardBox: React.FC<CardBoxProps> = (props: CardBoxProps): JSX.Element => {
  const {
    heading,
    actions,
    children,
    message,
    className = '',
    headingClassName = '',
    headingWrapperClassName = '',
    childrenClassName = '',
    actionsClassName = '',
    redirectReturnBtn,
    redirectReturnBtnBottom,
    headerElements,
    paddingBottom = '34px',
    paddingTop = '25px',
    paddingRight = '59px',
    paddingLeft = '30px',
    childrenPaddingTop = '32px',
    headerTitleClassName = '',
    noBackground = false,
    form = null
  } = props;

  const { isMobile } = useIsMobile();

  return (
    <div
      className={`card-box ${className}`}
      style={{
        paddingTop: form ? '25px' : paddingTop,
        paddingLeft: form ? '30px' : paddingLeft,
        paddingRight: form ? '30px' : paddingRight,
        paddingBottom: form ? '35px' : paddingBottom,
        flex: form ? '0 1 735px' : null,
        backgroundColor: noBackground ? 'transparent' : null,
        border: noBackground ? 'none' : null,
        boxShadow: noBackground ? 'none' : null
      }}
    >
      <Alert message={message} />
      <div
        className={`card-box-heading d-flex flex-wrap align-items-center justify-content-between ${headingWrapperClassName}`}
      >
        {!!redirectReturnBtn && (
          <div className="w-100 card-return-button">
            <ReturnButton redirectUrl={redirectReturnBtn} />
          </div>
        )}
        {heading && (
          <div className={`${headingClassName}${redirectReturnBtn ? ' d-flex flex-wrap align-items-center' : ''}`}>
            <span className={`font-poppins ${headerTitleClassName}`}>{heading}</span>
            {headerElements}
          </div>
        )}
        {actions && (
          <div className={`card-box-actions d-flex flex-wrap${actionsClassName ? ` ${actionsClassName}` : ''}`}>
            {actions}
          </div>
        )}
      </div>
      {children && (
        <div
          style={{
            paddingTop: form ? '32px' : childrenPaddingTop,
            paddingLeft: form && !isMobile ? '124px' : null,
            paddingRight: form && !isMobile ? '124px' : null
          }}
          className={`${childrenClassName} card-box-children`}
        >
          {children}
          {!!redirectReturnBtnBottom && (
            <div className="w-100 card-return-button bottom">
              <ReturnButton redirectUrl={redirectReturnBtnBottom} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CardBox;
