import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction } from 'modules/Layout/action';
// eslint-disable-next-line import/no-cycle
import { breadcrumbRouteForeignProceedingsReports } from '../../breadcrumbs';

const ForeignProceedingsReports = () => {
  const dispatch = useDispatch();

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  useEffect(() => {
    managePage({
      title: 'Raporty, statystyki, prognozy',
      breadcrumb: breadcrumbRouteForeignProceedingsReports()
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row users-view">
      <div className="col-12">
        <a
          target="_blank"
          rel="noreferrer"
          style={{ display: 'block' }}
          className="mb-2"
          href={`${process.env.BACKEND_ENDPOINT}/reports/2023_ASR-statistic.pdf`}
        >
          2023 ANNUAL STATISTICAL REPORT
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          style={{ display: 'block' }}
          className="mb-2"
          href={`${process.env.BACKEND_ENDPOINT}/reports/Raport-organizacje_miedzynarodowe.pdf`}
        >
          RAPORT W ZAKRESIE MOŻLIWOŚCI POZYSKIWANIA ZAMÓWIEŃ ORGANIZACJI MIĘDZYNARODOWYCH
        </a>
      </div>
    </div>
  );
};

export default ForeignProceedingsReports;
