import React, { useEffect, useState } from 'react';
import { Message, PaginationMeta, SearchParams } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import Listing from 'modules/Layout/component/Listing';
import CardBox from 'modules/Layout/component/CardBox';
import Alert from 'modules/Layout/component/Alert';
import PerPageTable from 'modules/Layout/component/Pagination/WithProps/PerPage';
import PaginationTable from 'modules/Layout/component/Pagination/WithProps';
import Office from 'modules/Office/model/Office';
import { OfficeExecutorRequestsValue } from 'modules/Office/type';
import { fetchExtendedExecutorRequestsList } from 'modules/Office/repository';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import ExtendedExecutorsRequestListTable from '../../../../container/ExtendedExecutorsRequestList/Table';

export interface Props {
  extended_office?: Office;
  showExtendedExecutorRequestReceivedModal?: (request_id: number) => void;
}

const DetailsExtendedExecutorsRequestList: React.FC<Props> = ({ extended_office }) => {
  const [extendedExecutorRequestsList, setExtendedExecutorRequestsList] = useState<OfficeExecutorRequestsValue[]>([]);
  const [meta, setMeta] = useState<PaginationMeta>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);

  const cancelToken = useCancelToken();
  const fetchExtendedExecutorsRequestList = async (payload?: SearchParams) => {
    setLoading(true);
    const {
      data,
      cancelled,
      message: messageResponse
    } = await fetchRequest(fetchExtendedExecutorRequestsList, extended_office?.id, payload, cancelToken);

    if (cancelled) return;
    if (data) {
      setExtendedExecutorRequestsList(data?.data);
      setMeta(data?.meta);
    }
    if (messageResponse) setMessage(messageResponse);
    setLoading(false);
  };

  useEffect(() => {
    if (extended_office) {
      fetchExtendedExecutorsRequestList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extended_office]);

  return (
    <CardBox heading="Lista zapytań">
      <div className="row users-view">
        <div className="col-12">
          <Alert message={message} />
          <div className="pzpeu-table-list inside-card-box">
            <Listing
              table={
                <ExtendedExecutorsRequestListTable
                  extendedExecutorRequestsList={extendedExecutorRequestsList}
                  officeId={extended_office?.id}
                />
              }
              pagination={<PaginationTable fetchList={fetchExtendedExecutorsRequestList} meta={meta} />}
              perPage={<PerPageTable fetchList={fetchExtendedExecutorsRequestList} meta={meta} />}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </CardBox>
  );
};

export default DetailsExtendedExecutorsRequestList;
