import { addToastAction, managePageAction } from 'modules/Layout/action';
import Loader from 'modules/Layout/component/Loader';
import { breadcrumbRouteOfficeUpdate } from 'modules/Office/breadcrumbs';
import Form from 'modules/Office/component/OfficeForm';
import Office, { createOffice } from 'modules/Office/model/Office';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Alert from 'modules/Layout/component/Alert';
import { Message, ValidationErrors } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { updateOfficeToastSuccess } from 'modules/Office/toasts';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_OFFICE, ROUTE_OFFICE_EXTENDED } from 'modules/Office/routes';
import { OfficeFormValues } from 'modules/Office/type';
import { fetchOffice, fetchOfficeExtended, updateExtendedOffice, updateOffice } from 'modules/Office/repository';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import updateRequest from 'modules/Shared/helper/APIRequests/updateRequest';
import CardBox from 'modules/Layout/component/CardBox';

interface OfficeBasicUpdateViewProps {
  extended?: boolean;
}

const OfficeBasicUpdateView = ({ extended }: OfficeBasicUpdateViewProps) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<{ user: Office }>();
  const [office, setOffice] = useState<Office>(null);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);

  const scrollToTopOnce = useRef<boolean>(true);

  const dispatchDeleteOfficeToast = () => dispatch(addToastAction(updateOfficeToastSuccess()));

  const cancelToken = useCancelToken();
  useEffect(() => {
    if (state?.user) {
      setOffice(createOffice(state.user));
    } else {
      (async () => {
        setFetching(true);
        const {
          data,
          cancelled,
          message: messageResponse
        } = await fetchRequest(extended ? fetchOfficeExtended : fetchOffice, id, cancelToken);

        if (cancelled) return;
        if (data) setOffice(createOffice(data.data));
        if (messageResponse) setMessage(messageResponse);
        setFetching(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (office) {
      const title = `${office.name?.trim() || 'Urzędy'} - edycja danych`;
      dispatch(
        managePageAction({
          title,
          breadcrumb: breadcrumbRouteOfficeUpdate(parseInt(id, 10) || 0, office.name?.trim())
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [office]);

  const updateOfficeAction = async (data: OfficeFormValues) => {
    setLoading(true);
    const {
      cancelled,
      message: messageRes,
      success,
      error
    } = await updateRequest(extended ? updateExtendedOffice : updateOffice, id, data, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDeleteOfficeToast();
      dispatch(push(getPathUrl(extended ? ROUTE_OFFICE_EXTENDED : ROUTE_OFFICE, { id })));
    } else {
      if (messageRes) setMessage(messageRes);
      if (error) setErrors(error);
      if (!scrollToTopOnce.current) scrollToTopOnce.current = true;
      setLoading(false);
    }
  };

  useEffect(() => {
    if (errors && scrollToTopOnce.current) {
      window.scrollTo({ top: 70 });
      scrollToTopOnce.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  if (fetching) return <Loader />;
  if (!fetching && !office && message)
    return (
      <div className="user-create-view d-flex justify-content-center">
        <CardBox form="small">{message && <Alert message={message} />}</CardBox>
      </div>
    );
  if (!fetching && office) {
    return (
      <div className="profile-update-view d-flex justify-content-center">
        <CardBox form="small" heading="Podstawowe dane urzędu">
          {loading && <Loader />}
          <Alert message={message} />
          {office && <Form errors={errors} officeData={office} submit={updateOfficeAction} disabled={loading} />}
        </CardBox>
      </div>
    );
  }

  return null;
};

export default OfficeBasicUpdateView;
