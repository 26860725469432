import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message, ValidationErrors } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';
import { breadcrumbRouteOfficeExtended } from 'modules/Office/breadcrumbs';
import Office, { createOffice } from 'modules/Office/model/Office';
import { extendedExecutorRequestReceived, fetchOfficeExtended } from 'modules/Office/repository';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import DetailsExtendedOffice from 'modules/Office/component/Details/Office/ExtendedOfficeDetails';
import { executorRequestReceivedToastSuccess } from '../../../toasts';
import { OfficeExecutorRequestReceivedValueSend } from '../../../type';
import { updateRequestWithData } from '../../../../Shared/helper/APIRequests/updateRequest';
import ExecutorRequestReceivedModal from '../../../component/Modal/ExecutorRequestReceived';

const DetailsExtended = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [office, setOffice] = useState<Office>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);
  const [displayExtendedExecutorRequestReceivedModal, setDisplayExtendedExecutorRequestReceivedModal] =
    useState<number>(null);
  const [loading, setLoading] = useState(false);

  const dispatchExecutorRequestReceivedToast = () => dispatch(addToastAction(executorRequestReceivedToastSuccess()));

  const cancelToken = useCancelToken();

  const fetchOfficeRequest = async () => {
    setFetching(true);
    const { data, cancelled, message: messageResponse } = await fetchRequest(fetchOfficeExtended, id, cancelToken);

    if (cancelled) return;
    if (data) setOffice(createOffice(data.data));
    if (messageResponse) setMessage(messageResponse);
    setFetching(false);
  };

  useEffect(() => {
    fetchOfficeRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (office) {
      const title = `${office.name?.trim() || 'Urzedy dot. PA'} - szczegóły`;
      dispatch(
        managePageAction({
          title,
          breadcrumb: breadcrumbRouteOfficeExtended(id, office.name?.trim())
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [office]);

  const extendedExecutorRequestReceivedRequest = async (data: OfficeExecutorRequestReceivedValueSend) => {
    setLoading(true);

    if (!displayExtendedExecutorRequestReceivedModal) return;

    if (message) setMessage(null);
    if (errors) setErrors(null);

    const {
      cancelled,
      message: messageRes,
      data: responseData,
      error
    } = await updateRequestWithData(
      extendedExecutorRequestReceived,
      id,
      displayExtendedExecutorRequestReceivedModal,
      data,
      cancelToken
    );

    if (cancelled) return;
    if (responseData) {
      dispatchExecutorRequestReceivedToast();
      setDisplayExtendedExecutorRequestReceivedModal(null);
      fetchOfficeRequest();
    }
    if (messageRes) setMessage(messageRes);
    if (error) setErrors(error);
    setLoading(false);
  };

  if (fetching && !office) return <Loader />;
  if (!fetching && !office && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );
  if (office) {
    return (
      <div className="row user-details-view position-relative">
        {fetching && <Loader />}
        {displayExtendedExecutorRequestReceivedModal && (
          <ExecutorRequestReceivedModal
            isOpen={!!displayExtendedExecutorRequestReceivedModal}
            loading={loading}
            onExecutorRequestReceived={extendedExecutorRequestReceivedRequest}
            toggle={() => setDisplayExtendedExecutorRequestReceivedModal(null)}
            message={message}
            errors={errors}
          />
        )}
        <DetailsExtendedOffice
          showExtendedExecutorRequestReceivedModal={(request_id: number) =>
            setDisplayExtendedExecutorRequestReceivedModal(request_id)
          }
          extended_office={office}
        />
      </div>
    );
  }

  return null;
};

export default DetailsExtended;
