import CardBox from 'modules/Layout/component/CardBox';
import React from 'react';
import { OfficeExtendedExecutorRequest } from '../../../../type';
import ExecutorRequestFileListTable from '../../../../container/ExecutorRequestFileList';
import ExtendedExecutorRequestBasicDataset from '../../ExtendedExecutorRequestDataset/Basic';

export interface Props {
  officeExtendedRequest: OfficeExtendedExecutorRequest;
  downloadFileRequest?: (fileId: number | string) => Promise<void>;
}

const ExtendedExecutorRequestDetailsBasic: React.FC<Props> = ({ officeExtendedRequest, downloadFileRequest }) => {
  return (
    <CardBox heading="Dane wniosku" paddingLeft="30px" paddingTop="25px" paddingRight="30px" paddingBottom="0">
      <ExtendedExecutorRequestBasicDataset officeExtendedRequest={officeExtendedRequest} />
      <div style={{ padding: '0 30px' }} className="information-list-item mt-2">
        <p className="information-list-paragraph">
          <strong style={{ marginRight: '0.25rem' }}>Załączniki:</strong>
          {!officeExtendedRequest?.files.length ? <span className="value">brak</span> : null}
        </p>
        {officeExtendedRequest?.files?.length ? (
          <ExecutorRequestFileListTable
            downloadFileRequest={downloadFileRequest}
            filesList={officeExtendedRequest?.files}
          />
        ) : null}
      </div>
    </CardBox>
  );
};

export default ExtendedExecutorRequestDetailsBasic;
