import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { Button, Form as FormStrap, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ROUTE_FOREIGN_PROCEEDINGS_ALERTS } from 'modules/ForeignProceedings/routes';
import { FiltersEntity } from 'modules/Layout/model/Filters';
// eslint-disable-next-line import/no-cycle
import { suffixLabel } from 'modules/Layout/helper/misc';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { AlertList } from '../../../model/Alert';
// eslint-disable-next-line import/no-cycle
import ForeignProceedingsAlertFieldset from '../../Fieldset/Main/Alert';

export interface Props {
  errors?: ValidationErrors;
  disabled?: boolean;
  alertData?: AlertFormData;
  submit: (data: FiltersEntity, name: string) => void;
  isEditing?: boolean;
  cancelRedirect?: string;
  fetchForeignProceedings?: () => Promise<void>;
  setValues?: React.Dispatch<React.SetStateAction<AlertFormData>>;
}

export type AlertFormData = AlertList & {
  filters: FiltersEntity;
};

const AlertForm: React.FC<Props> = ({
  errors,
  disabled,
  submit,
  alertData,
  isEditing,
  cancelRedirect,
  fetchForeignProceedings,
  setValues
}) => {
  const { filters, name } = alertData;

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const filtersToSave = {
      ...filters,
      published_date_from: '',
      published_date_to: ''
    };

    submit(filtersToSave, name);
  };

  const hasValue = () => !Object.values(alertData).some(Boolean);
  return (
    <FormStrap className="foreign-proceeding-form" onSubmit={onSubmit}>
      <ForeignProceedingsAlertFieldset
        disabled={disabled}
        errors={errors}
        values={alertData}
        setValues={setValues as any}
        isEditing={isEditing}
        required
      />
      {fetchForeignProceedings && (
        <FormGroup style={{ padding: '0 36px 0 12px' }}>
          <Label for="input-filters-published_date">{suffixLabel('Data publikacji')}</Label>
          <Input
            type="date"
            name="filters.published_date"
            id="input-filters-published_date"
            placeholder="Data publikacji"
            value={filters?.published_date_from}
            onChange={(event) =>
              setValues({
                ...alertData,
                filters: {
                  ...filters,
                  published_date_from: event.target.value,
                  published_date_to: event.target.value
                }
              })
            }
            invalid={hasError(errors, 'filters.published_date')}
          />
          {hasError(errors, 'filters.published_date_from') && (
            <FormFeedback>{getError(errors, 'filters.published_date_from')}</FormFeedback>
          )}
        </FormGroup>
      )}

      <div className="d-flex justify-content-center">
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: fetchForeignProceedings ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)',
            gap: '1.5rem'
          }}
          className="form-actions pzpeu-btn-flex"
        >
          {fetchForeignProceedings && (
            <Button
              type="button"
              color="secondary"
              className="pzpeu-btn-disabled waves-effect waves-light"
              disabled={hasValue() || disabled}
              onClick={() => fetchForeignProceedings()}
            >
              Wyszukaj
            </Button>
          )}
          <Button
            type="submit"
            color="primary"
            className="pzpeu-btn-disabled waves-effect waves-light"
            disabled={hasValue() || disabled}
          >
            Zapisz
          </Button>
          <Link to={cancelRedirect ?? ROUTE_FOREIGN_PROCEEDINGS_ALERTS} className="cancel m-0 waves-effect waves-light">
            Anuluj
          </Link>
        </div>
      </div>
    </FormStrap>
  );
};

export default AlertForm;
